import React, { useState, useEffect } from 'react';
import './App.css';
import BoostMeCard from './components/BoostMeCard'; // Importa el componente
import argFlag from './assets/argFlag.png';
import euroFlag from './assets/euroFlag.png';
import satFlag from './assets/satFlag.png';
import usdFlag from './assets/usdFlag.png';
import btcFlag from './assets/btcFlag.png';
import ukFlag from './assets/ukFlag.png';
import brFlag from './assets/brFlag.png';
import canFlag from './assets/canFlag.png';
import colFlag from './assets/colFlag.png';
import mexFlag from './assets/mexFlag.png';

function App() {
  const [amountFiat, setAmountFiat] = useState(0);
  const [amountBtc, setAmountBtc] = useState(0);
  const [amountSats, setAmountSats] = useState(0);
  const [currency, setCurrency] = useState('USD');
  const [rate, setRate] = useState(0);
  const [usdToArs, setUsdToArs] = useState(0);
  const [onChainFee, setOnChainFee] = useState(null); // Para la tarifa on-chain

  useEffect(() => {
    const fetchRate = async () => {
      try {
        let response;
        if (currency === 'ARS') {
          response = await fetch('https://api.bluelytics.com.ar/v2/latest');
          const data = await response.json();
          setUsdToArs(data.blue.value_avg); // Cambiado a value_avg
          const btcToUsdResponse = await fetch('https://api.coindesk.com/v1/bpi/currentprice/USD.json');
          const btcToUsdData = await btcToUsdResponse.json();
          setRate(btcToUsdData.bpi.USD.rate_float);
        } else {
          response = await fetch(`https://api.coindesk.com/v1/bpi/currentprice/${currency}.json`);
          const data = await response.json();
          setRate(data.bpi[currency].rate_float);
          setUsdToArs(1);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchMempoolFee = async () => {
      try {
        const response = await fetch('https://mempool.space/api/v1/fees/recommended');
        const feeData = await response.json();
        setOnChainFee(feeData.fastestFee); // Tarifa on-chain
      } catch (error) {
        console.error('Error fetching mempool data:', error);
      }
    };

    fetchRate();
    fetchMempoolFee();
  }, [currency]);

  const handleFiatChange = (e) => {
    let fiat = e.target.value;
    setAmountFiat(fiat);
    let btc;
    if (currency === 'ARS') {
      const usdEquivalent = fiat / usdToArs;
      btc = usdEquivalent / rate;
    } else {
      btc = fiat / rate;
    }
    setAmountBtc(btc.toFixed(8));
    setAmountSats(Math.round(btc * 100000000));
  };

  const handleBtcChange = (e) => {
    let btc = e.target.value;
    setAmountBtc(btc);
    if (currency === 'ARS') {
      const usdValue = btc * rate;
      setAmountFiat((usdValue * usdToArs).toFixed(2));
    } else {
      setAmountFiat((btc * rate).toFixed(2));
    }
    setAmountSats(Math.round(btc * 100000000));
  };

  const handleSatsChange = (e) => {
    const sats = e.target.value;
    const btc = sats / 100000000;
    setAmountSats(sats);
    setAmountBtc(btc.toFixed(8));
    if (currency === 'ARS') {
      const usdValue = btc * rate;
      setAmountFiat((usdValue * usdToArs).toFixed(2));
    } else {
      setAmountFiat((btc * rate).toFixed(2));
    }
  };

  const handleFiatBlur = () => {
    setAmountFiat(parseFloat(amountFiat).toFixed(2));
  };

  const handleBtcBlur = () => {
    setAmountBtc(parseFloat(amountBtc).toFixed(8));
  };

  const clearFields = () => {
    setAmountFiat(0);
    setAmountBtc(0);
    setAmountSats(0);
  };

  const calculateDisplayedRate = () => {
    return currency === 'ARS' ? (rate * usdToArs).toFixed(2) : rate.toFixed(2);
  };

  return (
    <div className="container">
      <h2 className="header">Convierte tu moneda fácilmente</h2>

      <button className="button">
        Sat Converter
        <div className="btc-price">
          <em>Bitcoin BTC / {calculateDisplayedRate()} {currency}</em>
        </div>
      </button>

      <div className="mempool-fees">
        <p>On-chain Fee: {onChainFee ? `${onChainFee} sat/vB` : 'Cargando...'}</p>
      </div>

      <div className="input-group">
        <input
          type="number"
          value={amountSats}
          onChange={handleSatsChange}
        />
        <span>Sat</span>
        <img src={satFlag} alt="Sat icon" />
        <button onClick={clearFields}>x</button>
      </div>

      <div className="input-group">
        <input
          type="number"
          value={amountBtc}
          onChange={handleBtcChange}
          onBlur={handleBtcBlur}
          step="0.00000001"
        />
        <span>BTC</span>
        <img src={btcFlag} alt="BTC icon" />
        <button onClick={clearFields}>x</button>
      </div>

      <div className="input-group">
        <input
          type="number"
          value={amountFiat}
          onChange={handleFiatChange}
          onBlur={handleFiatBlur}
        />
        <span>{currency}</span>
        <img 
          src={currency === 'USD' ? usdFlag : 
               currency === 'EUR' ? euroFlag : 
               currency === 'GBP' ? ukFlag : 
               currency === 'ARS' ? argFlag :
               currency === 'BRL' ? brFlag :
               currency === 'CAD' ? canFlag:
               currency === 'COP' ? colFlag:
               currency === 'MXN' ? mexFlag:
               null} 
          alt={`${currency} icon`} 
        />
        <button onClick={clearFields}>x</button>
      </div>

      <div className="input-group">
        <select
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
        >
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="ARS">ARS</option>
          <option value="BRL">BRL</option>
          <option value="CAD">CAD</option>
          <option value="COP">COP</option>
          <option value="MXN">MXN</option>
        </select>
      </div>
      <div className="text-credit">
        <p>Tipo de cambio proporcionado por<a href='https://www.coindesk.com/coindesk-api' target='_blank' rel="noreferrer"> Coindesk</a>.</p>
        <p>Tipo de cambio para ARS, Dólar blue promedio, proporcionado por 
          <a href='https://bluelytics.com.ar/' target='_blank' rel="noreferrer"> Bluelytics</a>.</p>
      </div>
      <BoostMeCard />
    </div>
  );
}

export default App;