// src/components/BoostMeCard.js
import React from 'react';
import './BoostMeCard.css'; // Importa el CSS para los estilos

const BoostMeCard = () => {
  return (
    <div className="card-container">
      <div className="card-content">
        <div className="logo">⚡</div>
        <h1>Boost me</h1>
        <img src={require('../assets/zapeame.jpg')} alt="QR Code" className="qr-code" />
        <div className="footer">
          <p>
            Si quieres apoyar mi trabajo, por favor, considera donar unos sats a mi dirección de lightning:
            <br />
            <strong>zapeame@getalby.com</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BoostMeCard;